import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "QNAP QuFTP Server for Alarm Recordings",
  "path": "/Frequently_Asked_Question/QNAP_QuFTP_Server/",
  "dateChanged": "2022-02-02",
  "author": "Mike Polinowski",
  "excerpt": "How to use QNAP QuFTP server to store alarm recordings of the camera?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - QNAP QuFTP Server for Alarm Recordings' dateChanged='2022-02-02' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to use QNAP QuFTP server to store alarm recordings of the camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/QNAP_QuFTP_Server/' locationFR='/fr/Frequently_Asked_Question/QNAP_QuFTP_Server/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "qnap-quftp-server-for-alarm-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#qnap-quftp-server-for-alarm-recordings",
        "aria-label": "qnap quftp server for alarm recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`QNAP QuFTP Server for Alarm Recordings`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How to use QNAP QuFTP server to store alarm recordings of the camera?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by installing the `}<strong parentName="p">{`QuFTP Server`}</strong>{` app from the `}<strong parentName="p">{`App Center`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1979f0aeb7f86d669256b4ad6b806775/ae77d/QNAP_QuFTP_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACfElEQVQoz33Ry0/TcAAH8P4BGm+aePNgCCAIygnjFQ8+Dhx981J5uY4oEEAIGuSRQDIWhiQelIBkMRETnYBjRoGNja3r2q5ssI2hbmNhHeuv3dputP2ZYbz6yff6PXzzRfqHnuomRzy4naYcDszmxO0OzOZwWZ1umwOzOlzWDbfNeRQXvo551p1uG+F1ffz0bni0B6m6eqW7q33NB+bsLFShepT/kBUFQji/unFJ24+cLSiqq7kzbw33GbFkgonHYwyT4HmO5zgOAEkUs5IkK7Kap6iqus+KigKnzdbTNZ1I6fmK5pbWrxZ7M/qisW3wZl33rYbe+y2DD57oatGRmsdD95oHgqFf2azEcRzP84eyDCE0mq2FdzuQouLypsZHbz5Yz1QN1PfMLa5Ri6vepRXSYtu02HzLa/TE2wWSDopCOplMplKpZPIAQjhtWjl1rRUpKa1o06JTs5bjZdpnui8xlkmkDkQ5C6SM0Wi0fDfj2zs44U8yiUgkure3F4lEFFled+Ltz4eRwuJyVKOZml0+WdnTNfaZ4TIpTmQYsM9w4XA8HktteEK+wK4kCgBwAIBMJgMhpEjylWEiX9aimsmZbycqX/ZN/oAQKoqqKKp8qBzm8vPord++wE9JFFiQx7IshJAgCN34OFJYVIai6Jjh/bGC25erOxvQwYa20cZ2XVOHXtP7umtkrlarcxN+ngPpdDqbzQqCACH0eDx6vR45V3Khrv4hACAWiQYCIZL0bfqDm1vb/u3d4E40tBsPhaOCIOZyOfXvXUc/kySZL5eUXrx+o/rt9MzCkpnyemma9nopbx5FUSRFERRJYhjmcrmc/+A4bjKZDAbDH4UD7YiXeY50AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1979f0aeb7f86d669256b4ad6b806775/e4706/QNAP_QuFTP_01.avif 230w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/d1af7/QNAP_QuFTP_01.avif 460w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/7f308/QNAP_QuFTP_01.avif 920w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/24329/QNAP_QuFTP_01.avif 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1979f0aeb7f86d669256b4ad6b806775/a0b58/QNAP_QuFTP_01.webp 230w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/bc10c/QNAP_QuFTP_01.webp 460w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/966d8/QNAP_QuFTP_01.webp 920w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/e4604/QNAP_QuFTP_01.webp 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1979f0aeb7f86d669256b4ad6b806775/81c8e/QNAP_QuFTP_01.png 230w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/08a84/QNAP_QuFTP_01.png 460w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/c0255/QNAP_QuFTP_01.png 920w", "/en/static/1979f0aeb7f86d669256b4ad6b806775/ae77d/QNAP_QuFTP_01.png 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1979f0aeb7f86d669256b4ad6b806775/c0255/QNAP_QuFTP_01.png",
              "alt": "QNAP QuFTP Server for Alarm Recordings",
              "title": "QNAP QuFTP Server for Alarm Recordings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then enable the FTP service on port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`21`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d895950ec2d012fe890137aab42007b/077b7/QNAP_QuFTP_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABq0lEQVQoz2OombCmbdLGzslb2yZtzq1flF23MKtmYU79ohwQY0FWzUIUVLswu35JTsOSzPol+Q2LGRRdK1TdqmSsi8UtCuv6167YdHTN9jOrtp5cs+PM2p1n1+7ARGfWbD+9cfe5mcsPMuj5NBv6t2p4NCg41pw4f/fvr+9//vz9//fX/////uMA//6BpO4/esVgEjHDIHSapv9kdb/JOw5fffP65f0Hj169ev36zZuvX7/++/f/799/aOj377////8/e+kOg5Jrk2Fgl5pHs6JL0/4TN968fvH6zZtfv3/9/fcXYgMm+PsXJH7p+gMGHd8u8/AJuv5dii7Nh8/c+frpw4f3nz6+//Tzx0+wE3E6+9b9Fwx6gX3GoRMNg/rVvbqOnn/w7/evl8/fvHv1/s3rt58/f/758ydOzQ9eMhiGTDYMnazp26fq3Xv47L0/v799//Hj379/f8DgHwj8R0MQZ9+8/5JB3XeCmm8/mJxw+My9x4+evn//GW48ntC+++gNg2bAFCgKnLpy+8VJMzfuPXz5/acfz159evHmM1b0/PWnNx++nrryFAA57NP0TdxSDgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d895950ec2d012fe890137aab42007b/e4706/QNAP_QuFTP_02.avif 230w", "/en/static/4d895950ec2d012fe890137aab42007b/d1af7/QNAP_QuFTP_02.avif 460w", "/en/static/4d895950ec2d012fe890137aab42007b/7f308/QNAP_QuFTP_02.avif 920w", "/en/static/4d895950ec2d012fe890137aab42007b/caf2c/QNAP_QuFTP_02.avif 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d895950ec2d012fe890137aab42007b/a0b58/QNAP_QuFTP_02.webp 230w", "/en/static/4d895950ec2d012fe890137aab42007b/bc10c/QNAP_QuFTP_02.webp 460w", "/en/static/4d895950ec2d012fe890137aab42007b/966d8/QNAP_QuFTP_02.webp 920w", "/en/static/4d895950ec2d012fe890137aab42007b/308c4/QNAP_QuFTP_02.webp 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d895950ec2d012fe890137aab42007b/81c8e/QNAP_QuFTP_02.png 230w", "/en/static/4d895950ec2d012fe890137aab42007b/08a84/QNAP_QuFTP_02.png 460w", "/en/static/4d895950ec2d012fe890137aab42007b/c0255/QNAP_QuFTP_02.png 920w", "/en/static/4d895950ec2d012fe890137aab42007b/077b7/QNAP_QuFTP_02.png 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d895950ec2d012fe890137aab42007b/c0255/QNAP_QuFTP_02.png",
              "alt": "QNAP QuFTP Server for Alarm Recordings",
              "title": "QNAP QuFTP Server for Alarm Recordings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Afterwards it is recommended to create a pure FTP user for the cameras. Make sure that this user has write access to the folder you want to use for the alarm recordings - in the example I use the `}<strong parentName="p">{`Public`}</strong>{` folder:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/53d03f4098a87fdfdd9baf92a945d34e/f5209/QNAP_QuFTP_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAByklEQVQoz3XQzWoTURQH8FkLUrVPIBU0T+DWhaC4EOvWN3EluHVVcC2KW2MCkxoxJO0kkYgLF7WNrcmk2TQzzcxkZs65H+feuXMlSYWC+Nue8+d8OHcfPqvWm9PJZHg4PDoaTqfTMAxns1kYhucrQRAGQXjJ+dksSOL4+csd58rNex8be9ZarQulFAJoSXaFlAZk9h+klLX2Q33Xub51v+p2rC2JiDGWZRnnHBG11sh4FC8YY0KI8hIpZVmWX/s9Z+PWo2pjX5MgoizL5vM5EaVpJqUsCs05W4cvZhJJKZMkEUJ0OnvOtTtPa82+tdYYA5xSFMAlCmmMUUrFSay1LoxJIC2MQcQ8zxHRGNNqtZyrWw9qnzxrLRdEPBGLiZKIyJRSWus4jtcrIKZaKyklADC2fES73XY2K49ru541KozTKGOQ50KIPM/XYURcLVUwlhEpxjgArK/wPM/ZrDypuvuC1NmCATIEQEQA0FpLKaMoAgClCLLIFIW15foLF+Eble365+XNxbL2X2Vpu/2DRnMQhIkxy85er+ds3N5+8epd99uB+2Xw/cfP09PJeOz7vj/+y185Ofn95m115/V7rzs4Pv41Go1c1/0DmUsSA4zjs/kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/53d03f4098a87fdfdd9baf92a945d34e/e4706/QNAP_QuFTP_03.avif 230w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/d1af7/QNAP_QuFTP_03.avif 460w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/7f308/QNAP_QuFTP_03.avif 920w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/2b0bf/QNAP_QuFTP_03.avif 1061w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/53d03f4098a87fdfdd9baf92a945d34e/a0b58/QNAP_QuFTP_03.webp 230w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/bc10c/QNAP_QuFTP_03.webp 460w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/966d8/QNAP_QuFTP_03.webp 920w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/44ba8/QNAP_QuFTP_03.webp 1061w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/53d03f4098a87fdfdd9baf92a945d34e/81c8e/QNAP_QuFTP_03.png 230w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/08a84/QNAP_QuFTP_03.png 460w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/c0255/QNAP_QuFTP_03.png 920w", "/en/static/53d03f4098a87fdfdd9baf92a945d34e/f5209/QNAP_QuFTP_03.png 1061w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/53d03f4098a87fdfdd9baf92a945d34e/c0255/QNAP_QuFTP_03.png",
              "alt": "QNAP QuFTP Server for Alarm Recordings",
              "title": "QNAP QuFTP Server for Alarm Recordings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Optional`}</strong>{`: Test the server with a FTP client software like `}<a parentName="p" {...{
        "href": "https://www.heise.de/download/product/filezilla-18785"
      }}>{`Filezilla`}</a>{` - use the just created `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ftpuser`}</code>{` for login. If you are then able to create a subfolder for the alarm videos in `}<strong parentName="p">{`Public`}</strong>{` everything has worked - i.e. also the camera will have the necessary rights with this user to manage the alarm videos:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/af4889cd09aede33aa6fe788f893a616/c23ad/QNAP_QuFTP_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABLklEQVQY02XOWU7DQBAEUF9gPIt79qVn8ZjNwiEoECLE/Y+FnPwgIr2PlrpUqmGZe+u9L0sqJZdaakuImEtMmDAnzD5E67zz4R8f4pBr633pywPWhpgRc5t7a80653ywzudSsVQf4r1BayP4ZK2zxkqpKKWcC0oZY4xzwRgHkACSi0lc8ZvrawghKKVTwhBTjEkbs4emPQcgpVRigpFxxjil7Fq635wLADl4HzJGrc00wQ7kjZjAOo85R+87lpByqa22HlNOWKTS2tiBUpbibTC7w42W89LO5+/D5/fx6+f98rN9XF5PF1CGEDIACGc1IeM40r8IGfd5jBtnlu34/Haa10N72eb1MK8HDpKQccgYEJNSWt7RxmpjJ1BYe5kfan98eT0+rdvjumnjQKpf3MAxNcZHoDsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/af4889cd09aede33aa6fe788f893a616/e4706/QNAP_QuFTP_04.avif 230w", "/en/static/af4889cd09aede33aa6fe788f893a616/d1af7/QNAP_QuFTP_04.avif 460w", "/en/static/af4889cd09aede33aa6fe788f893a616/7f308/QNAP_QuFTP_04.avif 920w", "/en/static/af4889cd09aede33aa6fe788f893a616/73333/QNAP_QuFTP_04.avif 1193w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/af4889cd09aede33aa6fe788f893a616/a0b58/QNAP_QuFTP_04.webp 230w", "/en/static/af4889cd09aede33aa6fe788f893a616/bc10c/QNAP_QuFTP_04.webp 460w", "/en/static/af4889cd09aede33aa6fe788f893a616/966d8/QNAP_QuFTP_04.webp 920w", "/en/static/af4889cd09aede33aa6fe788f893a616/1d5af/QNAP_QuFTP_04.webp 1193w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/af4889cd09aede33aa6fe788f893a616/81c8e/QNAP_QuFTP_04.png 230w", "/en/static/af4889cd09aede33aa6fe788f893a616/08a84/QNAP_QuFTP_04.png 460w", "/en/static/af4889cd09aede33aa6fe788f893a616/c0255/QNAP_QuFTP_04.png 920w", "/en/static/af4889cd09aede33aa6fe788f893a616/c23ad/QNAP_QuFTP_04.png 1193w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/af4889cd09aede33aa6fe788f893a616/c0255/QNAP_QuFTP_04.png",
              "alt": "QNAP QuFTP Server for Alarm Recordings",
              "title": "QNAP QuFTP Server for Alarm Recordings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now store the FTP access data in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Features/FTP/"
      }}>{`web interface of your camera`}</a>{` and test the setting:`}</p>
    <p><strong parentName="p">{`INSTAR Full HD Series`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2586a80eb95f4e2cb58be99238eda9d8/2a50c/QNAP_QuFTP_05a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB00lEQVQoz22QXW/TMBSG+ycQF1xWqWM77Vi7ql3cpkvqxG2z2LHdNk2/kDZxwTQEY+NLEwgQCIlLSi/hHzAhLhA/D63ZWoZ49Mrn6jk+5+RKpZJlWRjj7AUFgx68529+hS+/d15cBM//k/jVj8Hbn1Y9yOFr0ApQMML56eT86+jpcni27J9uok4W6mQhH39OzhbjZ8tS1bkhQwhN06RuazSQkodx1JP8KnHU9Ry7RerN3Wp9Z5u6DkboXxkA4AfBZDrT/YHS/XWk0l6bkkaTNJo2IazTNYxCDiGUbbuRfX80SpWS6i+klJRSssK2bcZYPp/PQQjXC6/l6XSSJInWWimls6IUpbTtee7eHiEkCAITmJcyumYtT8bjZDjoay2lFELwWHIhXM9zWq0GIWTXZixACF39vDkYANT3h+lUqKGQivPIYx3qd9qe17DrrtuyHadGiM+YZVkbGa4AwGS0/XAuHs2jgzTu7fM2YzSiVCvK0zi5d7jP74fRTAgTwhtjIwQNYIoevXjNf7/rfTiKWCgC5lNJg9mT3oNP6fHHL13+LRTnUQwwvpSLxWI2c9bIMIxbt+8UYNG2m7VarVyuVMvVnUq5sr2FkQkwBpZV3LqLEf4DiFyu6jGpzqQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2586a80eb95f4e2cb58be99238eda9d8/e4706/QNAP_QuFTP_05a.avif 230w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/d1af7/QNAP_QuFTP_05a.avif 460w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/7f308/QNAP_QuFTP_05a.avif 920w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/94001/QNAP_QuFTP_05a.avif 925w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2586a80eb95f4e2cb58be99238eda9d8/a0b58/QNAP_QuFTP_05a.webp 230w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/bc10c/QNAP_QuFTP_05a.webp 460w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/966d8/QNAP_QuFTP_05a.webp 920w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/31b98/QNAP_QuFTP_05a.webp 925w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2586a80eb95f4e2cb58be99238eda9d8/81c8e/QNAP_QuFTP_05a.png 230w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/08a84/QNAP_QuFTP_05a.png 460w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/c0255/QNAP_QuFTP_05a.png 920w", "/en/static/2586a80eb95f4e2cb58be99238eda9d8/2a50c/QNAP_QuFTP_05a.png 925w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2586a80eb95f4e2cb58be99238eda9d8/c0255/QNAP_QuFTP_05a.png",
              "alt": "QNAP QuFTP Server for Alarm Recordings",
              "title": "QNAP QuFTP Server for Alarm Recordings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`INSTAR Full HD Series (TLS)`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6e67c232d3e53c6ce98c5773b8321526/b12f7/QNAP_QuFTP_05b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACCklEQVQoz1WQz2/SYBzGe9nogQQMknAoPxMSCBAuZMJ1/gPMg55286Ixcih93xe0eBjJ4sE/QBYDqJSZmBgvelgTZ/wRJwWN0YtTs0w2lpmMIbS0pX1fUzan++S5fvI83y/FsiwAAELAArRYfnJx+cWlZXFhae1fyuJCWbxQFhfvvLx8++mVq9fOz8+n0+lsNktBCwQhLN64udbe2tjS3/1Q3n4fn+TNpvzq6/D15uhjjzwWP8zNnYtGo6FQKJPJUHmAOFhgOVjiS/t7u4RgjDE5xYRg1TQ0QkinLZ11uZxOJ03T4XCYYjnIQZgHgOdL29s/9Yk5MUzDxCeRlbGmG5qmE0LetySGYdxut91uj8fjFEQFqxxAnue73S7G2DTN/3vHfyGESJLk9Xp9Pp/f70+lUhRCBQARgMeypmmqquLT8mg0OpLbkhQMBhmGcblcyWSSQgVLznNTeWfXwMQ0McFYURRZlhVFGfT76nisGYZ1c6cTCAQcDofNZkskEsfy0ezdnS4xNENXTNMYDoeD34e/+gf7B4eKouqybMlS2+PxzE6JRCLWbA6gPMfxt5b2vrXIl3v4832iD60n6bL4qXd3vbe+0R3UH8j1ZuvZ8zNu9+zMDE3TsViMggixHIQIFYvFlZWK0HjYFASh2azX67VarVqtVWt1QRCERuPR6mqlUsnlctensCz7B4/SgNYP0M65AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6e67c232d3e53c6ce98c5773b8321526/e4706/QNAP_QuFTP_05b.avif 230w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/d1af7/QNAP_QuFTP_05b.avif 460w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/7f308/QNAP_QuFTP_05b.avif 920w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/2c8af/QNAP_QuFTP_05b.avif 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6e67c232d3e53c6ce98c5773b8321526/a0b58/QNAP_QuFTP_05b.webp 230w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/bc10c/QNAP_QuFTP_05b.webp 460w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/966d8/QNAP_QuFTP_05b.webp 920w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/52c2b/QNAP_QuFTP_05b.webp 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6e67c232d3e53c6ce98c5773b8321526/81c8e/QNAP_QuFTP_05b.png 230w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/08a84/QNAP_QuFTP_05b.png 460w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/c0255/QNAP_QuFTP_05b.png 920w", "/en/static/6e67c232d3e53c6ce98c5773b8321526/b12f7/QNAP_QuFTP_05b.png 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6e67c232d3e53c6ce98c5773b8321526/c0255/QNAP_QuFTP_05b.png",
              "alt": "QNAP QuFTP Server for Alarm Recordings",
              "title": "QNAP QuFTP Server for Alarm Recordings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`INSTAR WQHD Series (TLS)`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df4e7ce9ed176e6675bc32a26f71940a/e088a/QNAP_QuFTP_05c.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACSklEQVQoz1WR20/TUACH96APRhMDGYHggLaD0PacbQhyWbf2tKeXs67t2tMWelnkEowJxAfZ5jD45jPxzYAmPhgTwRhf/AvNWFD88r1++T38CrVaDUAIRKG6uukOvjpnv+zhdWv4w+hf3fFa71357366b75ZfiJtbQqCWKlWChBCUQSiwFefru2cfEhOP2enF+nwcntwcdd4cNF9+ykffnTjXMMyx7K16koBAADEERAA37GS0A0cK3At6hLqktAjltbUmhtqY11aXzGxfNQ7Oe6fbNW3uCfzo+VxDiGkNMyyPIq3wygeG8XbhLQUBTVlRZIadts57vWJ18GE8GVutAwhFAThJqZ5nidJEt6BEKIoiiRJ9brkeW6+v1ecmi4vLi2VFwuiKAIAeJ4fx2majpvoFtu2EVIRQk1Ztlvmi6PDuQWGYcoLpfmCeMs4zrIsiqIwDB3HsW3bbtkIIU3TMMaNRtM0DFlVFnleN63ZqZnCOPsbd7vdJNmhlGJd1zRNMzWsY9/3wzCklAZBMDlZnJmdhbWVxw8f/RdHUXSwt5ulCQ1DbLU0X8UUN5qyrCCkKEiWW4RMTEw+KZVApfLg3v2CIAgAgNHpFeh4QTvYIV4YBIFqEoWYqqo0NlZNHSmWWdex1W7PleZYjl17tjpdLN78DIAoCMtipbfv/X7f+X7mPU9jzw9c1/F2e7R/mb8+P/ejL3486AQ8hMzCCK5c/vezIAJqq69S/DJClqHrhmHo2HAiMzo0Onmm4gNN95G6tLzMMAzHcSzL/gEgIL+S8+E5gAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df4e7ce9ed176e6675bc32a26f71940a/e4706/QNAP_QuFTP_05c.avif 230w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/d1af7/QNAP_QuFTP_05c.avif 460w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/7f308/QNAP_QuFTP_05c.avif 920w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/9e7d9/QNAP_QuFTP_05c.avif 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df4e7ce9ed176e6675bc32a26f71940a/a0b58/QNAP_QuFTP_05c.webp 230w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/bc10c/QNAP_QuFTP_05c.webp 460w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/966d8/QNAP_QuFTP_05c.webp 920w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/3a6ad/QNAP_QuFTP_05c.webp 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df4e7ce9ed176e6675bc32a26f71940a/81c8e/QNAP_QuFTP_05c.png 230w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/08a84/QNAP_QuFTP_05c.png 460w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/c0255/QNAP_QuFTP_05c.png 920w", "/en/static/df4e7ce9ed176e6675bc32a26f71940a/e088a/QNAP_QuFTP_05c.png 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df4e7ce9ed176e6675bc32a26f71940a/c0255/QNAP_QuFTP_05c.png",
              "alt": "QNAP QuFTP Server for Alarm Recordings",
              "title": "QNAP QuFTP Server for Alarm Recordings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The camera should also store its alarm recordings on your QNAP from now on:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/63cad26ab42392761f0ad8105dcfd0b3/7a1fb/QNAP_QuFTP_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABRElEQVQY043MS0rDQACA4RyoeADpI23aTa20UDGregERdGOroNDSQF15C115AlfqAUTUQpMqxIUEnM5kMpl3khG6E1347f/fKj6fTPhgwKtBiwK/SeBzsBDQlyjg0OcokHgp8JLHS0HeU7SIv+Y09vPkUUe31vh8NBnunw0Pj48Orq+uuOCUEkpTSqn6QSolOWeEkJtneXlP7uaRVa7U2tu9WrNdKm2MTk6V1gghjDFOEowxIYRSmqZUSimEFEJIqT6gfomycCWsVqu10++77m61Wp16HgAgCIJoDUKolAIAhGEIAFBKQQjjODaZJvEKo5XlOE6323Vdt9FozGYXWuskSRhjlFLOeZZlQgjGGOdcay3XdJYxLqSUltNsdjqdwWCvXq9PPc8YUxSF+R/Ltmu2bfd7W5Xy5ngyMcbkef67//P4DV3AWmTYHq/aAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63cad26ab42392761f0ad8105dcfd0b3/e4706/QNAP_QuFTP_06.avif 230w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/d1af7/QNAP_QuFTP_06.avif 460w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/7f308/QNAP_QuFTP_06.avif 920w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/db5a9/QNAP_QuFTP_06.avif 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/63cad26ab42392761f0ad8105dcfd0b3/a0b58/QNAP_QuFTP_06.webp 230w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/bc10c/QNAP_QuFTP_06.webp 460w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/966d8/QNAP_QuFTP_06.webp 920w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/a820a/QNAP_QuFTP_06.webp 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63cad26ab42392761f0ad8105dcfd0b3/81c8e/QNAP_QuFTP_06.png 230w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/08a84/QNAP_QuFTP_06.png 460w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/c0255/QNAP_QuFTP_06.png 920w", "/en/static/63cad26ab42392761f0ad8105dcfd0b3/7a1fb/QNAP_QuFTP_06.png 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/63cad26ab42392761f0ad8105dcfd0b3/c0255/QNAP_QuFTP_06.png",
              "alt": "QNAP QuFTP Server for Alarm Recordings",
              "title": "QNAP QuFTP Server for Alarm Recordings",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      